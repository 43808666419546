<template>
  <Layer :title="invoiceCollectionId" @close="$emit('close')" width="60rem" padding="1rem">
    <h1 class="h4 ml-1">Invoices</h1>
    <p class="alert alert-info" v-if="invoices === null">Loading invoices...</p>
    <p class="alert alert-warning" v-else-if="invoices === []">No invoices found for {{ invoiceCollectionId }}</p>
    <table v-else class="table table-sm">
      <thead>
        <tr>
          <th>Invoice ID</th>
          <th>Name</th>
          <th>Sum</th>
          <th>Paid</th>
          <th>Transactions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="invoice in invoices"
          :key="invoice.id"
          :set="(transactionCounter = invoice.transactions ? invoice.transactions.length : 0)"
        >
          <td class="align-middle">
            <small class="text-secondary">{{ invoice.id.split('_')[0] }} / </small
            ><span>{{ invoice.id.split('_')[1] }}</span>
          </td>
          <td class="align-middle">{{ invoice.address.name1 }}</td>
          <td class="align-middle">{{ formatCurrency(invoice.sums.gross.total) }}</td>
          <td class="align-middle">
            {{ invoice.completelyPaid ? '✅' : '❌' }} {{ formatCurrency(invoice.paidAmount || 0) }}
          </td>
          <td class="align-middle">
            <button class="btn" @click="loadTransactions(invoice.id)" :disabled="transactionCounter === 0">
              {{ transactionCounter }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <h1 class="h4 ml-1">Transactions</h1>
    <p class="alert alert-info" v-if="transactions === null">Loading transactions...</p>
    <p class="alert alert-warning" v-else-if="transactions === []">No transactions found</p>
    <table v-else class="table table-sm">
      <thead>
        <tr>
          <th>Date</th>
          <th>Purpose</th>
          <th>Assigned</th>
          <th>Sum</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transaction in transactions" :key="transaction.id">
          <td class="align-middle">
            {{ transaction.date | date }}
          </td>
          <td class="align-middle">{{ transaction.purpose }}</td>
          <td class="align-middle">{{ formatCurrency(transaction.assignedAmount) }}</td>
          <td class="align-middle">{{ formatCurrency(transaction.amount) }}</td>
        </tr>
      </tbody>
    </table>
  </Layer>
</template>

<script>
import Layer from '@/shared/Layer'
import { db } from '@/shared/firebase'

export default {
  components: { Layer },
  props: {
    invoiceCollectionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      invoices: null,
      transactions: null
    }
  },
  created() {
    this.loadInvoices()
  },
  methods: {
    async loadInvoices() {
      this.invoices = null
      const invoices = await db
        .collection(`invoices`)
        .where('invoiceCollectionId', '==', this.invoiceCollectionId)
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      let transactionIds = []
      invoices.forEach(invoice => {
        if (invoice.transactions) {
          invoice.transactions.forEach(transaction => {
            if (transaction.type === 'deposit') transactionIds.push(transaction.transactionId)
          })
        }
      })
      this.loadTransactions([...new Set(transactionIds)])
      this.invoices = invoices
    },
    async loadTransactions(transactionIds) {
      const transactions = []
      await Promise.all(
        transactionIds.map(transactionId => {
          return db
            .doc(`private/banking/transactions/${transactionId}`)
            .get()
            .then(doc => {
              transactions.push({ ...doc.data(), id: doc.id })
            })
        })
      )
      this.transactions = transactions
    }
  },
  watch: {
    invoiceCollectionId() {
      this.loadInvoices()
    }
  },
  filters: {
    date(date) {
      return `${date.substr(8, 2)}.${date.substr(5, 2)}.${date.substr(0, 4)}`
    }
  }
}
</script>
