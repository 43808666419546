<template>
  <div v-if="orders.length > 0">
    <div v-if="selectedOrders.length > 1" class="mb-3">
      Batch Edit: <button class="btn btn-outline-secondary btn-sm" @click="mergeOrders">Merge</button>
    </div>
    <table class="table table-hover mb-4">
      <thead class="thead-light">
        <tr>
          <th scope="col">Organization</th>
          <th scope="col" class="text-right">Ordered</th>
          <th scope="col" class="text-right">Shipped</th>
          <th scope="col" class="text-right">Billing Doc</th>
          <th scope="col" class="text-right">Billing Mail</th>
          <th scope="col" class="text-right">Paid</th>
          <th scope="col" class="text-right">Shipments</th>
          <th scope="col" class="text-right">Revenue</th>
          <th scope="col" colspan="2"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(order, i) in orders"
          :key="i"
          :style="`border-left: 4px ${order.state === 'shipped' ? 'forestgreen' : 'tomato'} solid`"
        >
          <td class="align-middle">
            <input type="checkbox" v-model="selectedOrders" :value="order.id" class="mr-2" />
            <select @change="changePrio(order.id, $event.target.value)">
              <option :value="1" :selected="order.whPrio === 1">P1</option>
              <option :value="2" :selected="order.whPrio === 2 || typeof order.whPrio === 'undefined'">P2</option>
              <option :value="3" :selected="order.whPrio === 3">P3</option>
              <option :value="0" :selected="order.whPrio === 0">P0</option>
            </select>
            <router-link :to="{ name: 'order', params: { orderId: order.id } }">
              {{ order.organizationName }}
            </router-link>
            <span v-if="order.type === 'kiosk'" class="badge badge-info ml-1">Kiosk</span>
            <span v-if="order.mergedOrder" class="badge badge-info ml-1">MERGED</span>
          </td>
          <td class="text-right align-middle text-nowrap">
            {{ formatDate(order.createdAt.toDate(), true) }}
          </td>
          <td class="text-right align-middle text-nowrap">
            <span v-if="order.deliveryDate">{{ formatDate(order.deliveryDate.toDate(), true) }}</span>
            <span v-else>---</span>
          </td>
          <td class="text-right align-middle" v-if="order.invoiceCollectionId">
            <button
              class="btn btn-sm btn-outline-success text-nowrap"
              @click="openInvoiceCollectionPdf(order.invoiceCollectionId)"
              v-if="order.billingDocumentsGenerated"
            >
              {{ order.invoiceCollectionId }}
            </button>
            <button disabled class="btn btn-sm btn-outline-secondary text-nowrap" v-else>
              {{ order.invoiceCollectionId }}
            </button>
          </td>
          <td class="text-right align-middle" v-else>---</td>
          <td class="text-right align-middle">{{ order.billingMailSent ? '✅' : '❌' }}</td>
          <td class="text-right align-middle">
            <button
              class="btn"
              @click="paymentDetailsLayerId = order.invoiceCollectionId || null"
              v-if="order.deliveryDate && order.deliveryDate.toDate() >= new Date('2021-03-01')"
            >
              <span v-if="!order.completelyPaid && !order.paidAmount">🔴</span>
              <span v-else-if="order.completelyPaid">🟢</span>
              <span v-else>🟠</span>
            </button>
          </td>
          <td v-if="order.shipments" class="text-right align-middle">
            <div class="d-inline-block" v-for="(shipment, i) in order.shipments" :key="shipment.shipmentId">
              <button
                v-if="shipment.statusCode"
                class="orders-list__delivery-button"
                :class="`orders-list__delivery-button--${shipment.statusCode}`"
                @click="shipmentTrackingLayerId = shipment.shipmentId"
              >
                P{{ i + 1 }}
              </button>
              <a
                :href="
                  `https://nolp.dhl.de/nextt-online-public/set_identcodes.do?lang=de&idc=${shipment.shipmentNumber}&cid=vlsarchive`
                "
                class="orders-list__delivery-button orders-list__delivery-button--unknown"
                target="_blank"
                v-else
                >P{{ i + 1 }}</a
              >
            </div>
          </td>
          <td class="text-right align-middle" v-else>---</td>
          <td class="text-right align-middle text-nowrap">
            <span v-if="order.statistic">
              {{ formatCurrency(order.statistic.sum.revenue.total.gross) }}
            </span>
          </td>
          <td class="text-right align-middle">
            <button
              v-if="
                order.billingDocumentsGenerated &&
                  order.organization &&
                  order.organization.payment &&
                  order.organization.payment.method === 'sepa'
              "
              class="btn btn-sm"
              :class="order.sepaPaymentTriggered ? 'btn-outline-success' : 'btn-outline-danger'"
              @click="showSepaDetails(order)"
            >
              SEPA
            </button>
          </td>
          <td class="text-right align-middle">
            <DotMenu>
              <li v-if="order.invoiceCollectionId" @click="paymentDetailsLayerId = order.invoiceCollectionId">
                Show Payment Details
              </li>
              <hr />
              <li>
                <router-link :to="`/organizations/${order.organizationId}`">Go To Organization</router-link>
              </li>
              <li @click="copyToClipboard(order.id)">Copy Order ID</li>
              <li
                @click="deleteOrder(order)"
                class="text-danger"
                v-if="order.organizationId === 'x4PfgpbAOwTc3047RUCf'"
              >
                Delete Order
              </li>
            </DotMenu>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col" colspan="7"></th>
          <th scope="col" class="text-right">
            <!-- {{ formatCurrency(statistics.grossRevenue) }}
            <small>({{ formatCurrency(statistics.totalNetRevenue) }})</small> -->
            {{ formatCurrency(statistics.totalGrossRevenue) }}
            <small v-if="statistics.averageNumberOfResidents" class="d-block ml-2"
              >👵🏼 {{ statistics.averageNumberOfResidents }} à {{ formatCurrency(statistics.averageResidentRevenue) }}
            </small>
          </th>
          <th colspan="2"></th>
        </tr>
      </tfoot>
    </table>
    <ShipmentTrackingLayer
      v-if="shipmentTrackingLayerId"
      :shipmentIds="[shipmentTrackingLayerId]"
      @close="shipmentTrackingLayerId = null"
    />
    <PaymentDetailsLayer
      v-if="paymentDetailsLayerId"
      :invoiceCollectionId="paymentDetailsLayerId"
      @close="paymentDetailsLayerId = null"
    />
  </div>
  <div class="alert alert-warning  mb-4" role="alert" v-else>No orders in this period</div>
</template>

<script>
import PaymentDetailsLayer from '@/components/PaymentDetailsLayer'
import ShipmentTrackingLayer from '@/components/ShipmentTrackingLayer'
import DotMenu from '@/shared/DotMenu'
import { auth, db, env, fb, storage } from '@/shared/firebase'

export default {
  components: { DotMenu, ShipmentTrackingLayer, PaymentDetailsLayer },
  data() {
    return {
      selectedOrders: [],
      userIsMarcel: auth.currentUser.uid === 'D0N7x7eca9a8tRWJb4UBYyBkgqR2',
      shipmentTrackingLayerId: null,
      paymentDetailsLayerId: null
    }
  },
  props: ['orders'],
  methods: {
    async openInvoiceCollectionPdf(invoiceCollectionId) {
      try {
        const bucketName = env === 'production' ? 'emma-billings' : 'emma-billings-dev'
        const invoiceCollectionUrl = await storage
          .refFromURL(`gs://${bucketName}/billings/${invoiceCollectionId}.pdf`)
          .getDownloadURL()

        window.open(invoiceCollectionUrl, '_blank')
      } catch (error) {
        alert(`Couldn't get PDF: ${error.code}`)
      }
    },
    changePrio(orderId, whPrio) {
      db.doc(`orders/${orderId}`).update({ whPrio: parseFloat(whPrio) })
    },
    async resetPackingStatus(order) {
      const update = {
        state: 'preparing',
        pickedItems: fb.firestore.FieldValue.delete(),
        residents: order.residents
      }
      update.residents.forEach(resident => {
        delete resident.shoppingList.completed
        resident.shoppingList.items.forEach(item => {
          delete item.boxSpaces
          delete item.packed
        })
      })
      db.doc(`orders/${order.id}`).update(update)
    },
    deleteOrder(order) {
      const confirmed = confirm(
        `Are you sure you want to delete ${order.organizationName}'s order from ${order.dateString}?`
      )
      if (confirmed) db.doc(`orders/${order.id}`).delete()
    },
    calculateKioskExpiryDates(orderId) {
      const order = this.orders.find(o => o.id === orderId)
      const kioskOrder = _.cloneDeep(order.kioskOrder)
      order.pickedItems.forEach(pickedItem => {
        if (pickedItem.expiryDate) {
          const { amount, expiryDate, productId } = pickedItem
          const itemIndex = kioskOrder.items.findIndex(i => i.productId === productId)
          const item = kioskOrder.items[itemIndex]
          if (!item.expiryDates) item.expiryDates = []
          item.expiryDates.push({ amount, expiryDate })
        }
      })
      db.doc(`orders/${orderId}`).update({ kioskOrder })
    },
    async mergeOrders() {
      const orders = this.orders.filter(o => this.selectedOrders.includes(o.id))
      if (orders.length === 1) return alert('You only have selected 1 order. Nothing to merge')

      const checkKeys = ['deliveryFee', 'organizationId', 'state']
      let error = null
      checkKeys.forEach(key => {
        const values = [
          ...new Set(
            orders.map(o => {
              return o[key]
            })
          )
        ]
        if (values.length > 1)
          error = `Orders showing different values in the field "${key}". Can't merge orders with differnt keys in that field.`
      })

      if (error) return alert(error)

      const confirmed = confirm(`Are you sure to merge ${orders.length} orders?`)
      if (confirmed) {
        const { homeOrder, residents } = mergeShoppingLists(orders)
        const newOrder = {
          billingDocumentsGenerated: false,
          billingMailSent: false,
          createdAt: new Date(),
          deliveryFee: orders[0].deliveryFee,
          organizationId: orders[0].organizationId,
          organizationName: orders[0].organizationName,
          type: orders[0].type,
          state: 'preparing',
          mergedOrder: true,
          mergedOrders: orders.map(order => {
            return {
              createdAt: order.createdAt,
              id: order.id
            }
          }),
          residents
        }
        if (homeOrder) newOrder.homeOrder = homeOrder
        const createMergedOrders = orders.map(order => {
          return db.doc(`mergedOrders/${order.id}`).set(order)
        })
        await Promise.all(createMergedOrders)
        await db.collection('orders').add(newOrder)
        orders.forEach(order => {
          db.doc(`orders/${order.id}`).delete()
        })
      }
    }
  },
  computed: {
    statistics() {
      let totalNetRevenue = 0
      let totalGrossRevenue = 0
      let averageNumberOfResidents = null
      let averageResidentRevenue = null
      const numberOfResidentsArray = []
      const residentRevenuesArray = []
      this.orders.forEach(order => {
        totalNetRevenue += _.get(order, 'statistic.sum.revenue.total.net', 0)
        totalGrossRevenue += _.get(order, 'statistic.sum.revenue.total.gross', 0)
        if (order.statistic && order.statistic.resident) {
          numberOfResidentsArray.push(order.statistic.sum.numberOfResidents)
          residentRevenuesArray.push(
            order.statistic.resident.revenue.total.gross / order.statistic.resident.numberOfResidents
          )
        }
      })
      if (numberOfResidentsArray.length !== 0) {
        averageNumberOfResidents = Math.round(
          numberOfResidentsArray.reduce((a, b) => a + b, 0) / numberOfResidentsArray.length
        )
        averageResidentRevenue = residentRevenuesArray.reduce((a, b) => a + b, 0) / residentRevenuesArray.length
      }
      return { totalNetRevenue, totalGrossRevenue, averageNumberOfResidents, averageResidentRevenue }
    }
  }
}

const mergeShoppingLists = orders => {
  let homeOrder = null
  const residents = []
  const homeOrderItems = {}
  orders.forEach(order => {
    order.homeOrder?.shoppingList?.items?.forEach(item => {
      homeOrderItems[item.productId] = homeOrderItems[item.productId] || 0 + item.amount
    })
    order.residents?.forEach(resident => {
      residents.push(resident)
    })
  })
  if (Object.keys(homeOrderItems).length !== 0) {
    homeOrder = {
      shoppingList: {
        items: Object.keys(homeOrderItems).map(productId => {
          return {
            productId,
            amount: homeOrderItems[productId]
          }
        })
      }
    }
  }
  return { homeOrder, residents }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.orders-list {
  &__delivery-button {
    @extend %cleanbutton;

    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;

    background-position: center center;
    background-repeat: no-repeat;

    font-size: 0.8rem;
    text-align: center;
    line-height: 2rem;

    &:hover {
      text-decoration: none;
    }

    &--delivered {
      background-image: url(../assets/icons/delivery-status/delivered.svg);
    }
    &--failure {
      background-image: url(../assets/icons/delivery-status/failure.svg);
    }
    &--pre-transit {
      background-image: url(../assets/icons/delivery-status/pre-transit.svg);
    }
    &--transit {
      background-image: url(../assets/icons/delivery-status/transit.svg);
    }
    &--unknown {
      background-image: url(../assets/icons/delivery-status/unknown.svg);
    }
  }
}
</style>
